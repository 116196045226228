import { GET, POST } from '@/utils/axios'

export const getSurvey = async criteria => await POST('/survey', criteria)

export const getActiveSurveys = async ({ userId, source, walkin }) => {
  const options = { useAuthorization: true }

  if (source) {
    if (walkin) {
      return await GET(
        `survey/tasterSurveys/${userId}/${source}/${walkin}`,
        options
      )
    }
    return await GET(`survey/tasterSurveys/${userId}/${source}`, options)
  }

  return await GET(`survey/tasterSurveys/${userId}`, options)
}

export const surveyLogin = async formData =>
  await POST('survey/survey-login', formData)

export const surveyReject = async enrollmentId =>
  await GET(`survey/enrollment/${enrollmentId}/reject`)

export const getTasterPdfLink = async formData =>
  await POST('survey/tasterPdf', formData)

export const checkMetroCodeDate = async surveyId =>
  await GET(`survey/check-metro-code-date/${surveyId}`, {
    useAuthorization: true
  })
