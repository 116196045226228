import axios from 'axios'
import { GET, POST, DELETE } from '@/utils/axios'

export const saveBusinessEmail = async formData =>
  await axios.post(
    'https://business-api.flavorwiki.com/api/marketing/submit-join-mail',
    formData
  )

export const signIn = async formData => await POST('users/login', formData)

export const signupWithEmail = async formData =>
  await POST('users/signup-with-email', formData)

export const signupWithSocial = async formData =>
  await POST('users/signup-with-social', formData)

export const forgotPassword = async formData =>
  await POST('users/forgotPassword', formData)

export const resetPassword = async formData =>
  await POST('users/resetPassword', formData)

export const resendVerificationEmail = async formData =>
  await POST('users/resend-verification-email', formData)

export const checkUserEmail = async formData =>
  await POST('users/check-user-email', formData)

export const socialLogin = async formData =>
  await POST('/social-login', formData)

export const impersonate = async formData =>
  await POST('users/verify-impersonation-token', formData)

export const verifyEmail = async formData =>
  await POST('users/verify-email', formData)

export const getProfile = async () =>
  await GET('users/me', { useAuthorization: true })

export const updateProfile = async formData =>
  await POST('users/update-profile', formData, { useAuthorization: true })

export const updatePassword = async formData =>
  await POST('users/update-password', formData, { useAuthorization: true })

export const deleteAccount = async formData =>
  await DELETE(`users/delete/${formData.email}`, { useAuthorization: true })

export const twoFACheckIfUserAuthenticated = async user =>
  await GET(`users/twoFA-check?userId=${user.id}`)

export const getTwofaMobile = async formData =>
  await GET(
    `users/getTwofaMobile?phonenumber=${formData.mobileNumber2FA}&channel=${formData.channel}&userId=${formData.userId}&mobileNumber=${formData.mobileNumber}`
  )

export const verifyTwofaMobile = async formData =>
  await GET(
    `users/verifyTwofaMobile?phonenumber=${formData.mobileNumber2FA}&code=${formData.twoFACode}&userId=${formData.userId}&mobileNumber=${formData.mobileNumber}`
  )
